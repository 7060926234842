import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

const NewLoader = ({ className }) => {
  return (
    <div className={className ? `${className} ${styles.wrap}` : styles.wrap}>
      <div className={styles.loop}>
        <svg viewBox="0 0 200 164">
          <path
            className={styles.path}
            fill="none"
            stroke="#F59700"
            strokeWidth="6"
            d="M153.672 72.919C153.672 67.7966 149.454 63.5782 144.331 63.5782H92.2033L77.1374 90.6968C77.1374 90.6968 116.007 90.8475 115.405 90.8475C114.953 90.8475 97.0244 122.335 97.0244 122.335L69.7551 122.185L90.3954 157.891C96.1205 164.369 102.9 165.273 108.625 157.891L149.604 86.3277C149.604 86.3277 153.823 78.7947 153.823 76.6855C153.823 74.5763 153.672 72.919 153.672 72.919Z"
            // fill="#F59700"
          />
          <path
            className={styles.path}
            fill="none"
            stroke="#F59700"
            strokeWidth="6"
            d="M46.7043 40.9793H23.3522L0 0.301331H61.4689C66.1394 0.301331 67.7966 3.46517 65.2354 7.38231L46.7043 40.9793Z"
            // fill="#F59700"
          />
          <path
            className={styles.path}
            fill="none"
            stroke="#F59700"
            strokeWidth="6"
            d="M58.1543 34.9529C58.1543 34.9529 50.3201 50.0188 45.047 59.8117C42.6364 64.4821 40.5272 70.0565 42.9378 74.7269C50.9227 89.9435 67.9472 117.815 67.7965 117.815C67.6459 117.815 66.1393 112.994 70.2071 103.503L105.009 40.9793C105.009 40.9793 152.919 40.9793 175.819 40.9793L199.021 0.301318L83.7664 0C76.5347 0 72.6176 9.03955 72.6176 9.03955L58.1543 34.9529Z"
            // fill="#F59700"
          />
        </svg>
      </div>
      <span className={styles.blink_me}>Loading ...</span>
    </div>
  );
};
NewLoader.propTypes = {
  className: PropTypes.string
};
NewLoader.defaultProps = { className: '' };
export default NewLoader;
