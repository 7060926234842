import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { content, navBarRoute } from '../../../helpers/landingContent';
import styles from '../style.module.css';
import logo from '../../../assets/logos/logo_white_new.svg';

const SimpleFooter = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  function detectWindowSize() {
    if (window.innerWidth < 992) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  const partNav1 = navBarRoute.slice(0, 6);
  const partNav2 = navBarRoute.slice(6).concat(content.footer.others);
  const simpleFooterNav = navBarRoute.slice(8).concat(content.footer.others);

  const handleOnSelect = (eventKey, event) => {
    if (eventKey.startsWith('/')) {
      event.preventDefault();
      navigate(`${eventKey}`);
      return;
    }
    const { pathname } = window.location;
    if (pathname !== '/') {
      navigate(`/${eventKey}`);
      return;
    }
    event.preventDefault();
    document.querySelector(eventKey)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };
  useEffect(() => {
    detectWindowSize();
    window.addEventListener('resize', detectWindowSize);
    return () => {
      window.removeEventListener('resize', detectWindowSize);
    };
  }, []);
  return (
    <div className={`${styles.footer_area} container-fluid py-4`}>
      <div className="wrap">
        <ul className={`${styles.simple_footer} small`}>
          {/* <li>Terms of Use</li>
          <li>Privacy Policy</li>
          <li>Cookie Preferences</li> */}
          {/* {simpleFooterNav.map((item, index) => {
            return (
              <li key={index}>
                <Nav.Link key={index} href={item.path}>
                  {item.name}
                </Nav.Link>
              </li>
            );
          })} */}
          <li>Copyright 2023 © TezSign. All rights reserved.</li>
        </ul>
      </div>
    </div>
  );
};

export default SimpleFooter;
