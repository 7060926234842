import { Dropdown } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const MyDropdownBtn = ({
  label,
  handleEdit,
  data,
  handleDelete,
  handleView,
  className,
  handleUpdatePlanStatus
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        className={className || 'btn_default_sm'}
        id="dropdown-basic"
      >
        Select
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.drop_menu}>
        {label === 'notification' && (
          <Dropdown.Item
            onClick={() => {
              handleView(data);
            }}
            className="body"
          >
            <i className="icon_eye me-3" />
            View
          </Dropdown.Item>
        )}
        {label === 'plan' ||
          (label === 'feature' && data.useCount === 0) ||
          (label === 'notification' &&
            data.status === 0 &&
            data.channel !== 1 && (
              <Dropdown.Item
                onClick={() => {
                  handleEdit(data);
                }}
                className="body"
              >
                <i className="icon_edit me-3" />
                Edit
              </Dropdown.Item>
            ))}

        {data.planStatus === '0' && label === 'plan' && (
          <Dropdown.Item
            onClick={() => {
              handleUpdatePlanStatus(data, 1);
            }}
            className="body"
          >
            <i className="icon_restore me-3" />
            Publish
          </Dropdown.Item>
        )}
        {data.planStatus === '1' && label === 'plan' && (
          <Dropdown.Item
            onClick={() => {
              handleUpdatePlanStatus(data, 2);
            }}
            className="body"
          >
            <i className="icon_delete me-3" />
            Cancel Publish
          </Dropdown.Item>
        )}
        {(label === 'plan' ||
          label === 'feature' ||
          (label === 'notification' && data.status === 0)) && (
          <Dropdown.Item
            onClick={() => {
              handleDelete(data);
            }}
            className="body"
          >
            <i className="icon_remove me-3" />
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
MyDropdownBtn.propTypes = {
  data: PropTypes.shape({
    planStatus: PropTypes.string,
    useCount: PropTypes.number,
    status: PropTypes.number,
    channel: PropTypes.number
  }).isRequired,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  handleView: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  handleUpdatePlanStatus: PropTypes.func
};
MyDropdownBtn.defaultProps = {
  handleEdit: null,
  handleDelete: null,
  handleView: null,
  className: null,
  handleUpdatePlanStatus: null
};

export default MyDropdownBtn;
