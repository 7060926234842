import { get, post, put } from './httpService';

const apiUrl = process.env.REACT_APP_INBOX_API_URL;

const getNotificationList = async filterParam => {
  const url = new URL(`${apiUrl}/admin/notifications`);
  Object.entries(filterParam).forEach(([key, value]) => {
    if (value || value === 0) url.searchParams.append(key, value);
  });
  const response = await get(url);
  return response;
};
const getNotificationDetail = async id => {
  const response = await get(`${apiUrl}/admin/notifications/${id}`);
  return response;
};
const createNotification = async params => {
  try {
    const response = await post(`${apiUrl}/admin/notifications`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const updateNotification = async (params, id) => {
  try {
    const response = await put(`${apiUrl}/admin/notifications/${id}`, params);
    return response;
  } catch (error) {
    return false;
  }
};
const deleteNotification = async id => {
  try {
    const response = await put(`${apiUrl}/admin/notifications/${id}`, {
      isDeleted: 1
    });
    return response;
  } catch (error) {
    return false;
  }
};
const searchNotificationByName = async name => {
  const response = await get(`${apiUrl}/admin/notifications?query=${name}`);
  return response;
};

export {
  getNotificationList,
  getNotificationDetail,
  createNotification,
  updateNotification,
  deleteNotification,
  searchNotificationByName
};
