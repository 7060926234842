// DID: We offer a secure method for storing documents using our DID feature and wallet. Documents are protected by the user’s private and public keys and stored in a decentralized storage network. Both the creator and signatory must have a Tezsign wallet and a unique DID number to access the documents.
const fileShareOptionTips = `<div className='m-0 p-0'>
Secure File Sharing: Our Secure File Sharing feature ensures that your files are stored securely within a decentralized data storage network and are encrypted end-to-end, providing an additional layer of protection. Recipients can conveniently access the shared document by simply clicking on the link provided in the email.<br/><br/>
Smart Form: Deliver on-demand forms and signatures with no integration, no hassle.</div>`;
const additionalSecurityTips = `<div className='m-0 p-0'>
File Hash Validation: With our File Hash Validation feature, signatories are required to upload the original document file before commencing the signing process. This robust measure ensures that the signatory digitally signs the exact document you initially provided, thereby safeguarding the authenticity, tamper-proof nature, and integrity of the document contents.<br/><br/>
DID: We offer a secure method for storing documents using our DID feature and wallet. Documents are protected by the user's private and public keys and stored in a decentralized storage network. Both the creator and signatory must have a TezSign wallet and a unique DID number to access the documents.</div>`;
const templateFileTips = `<div className='m-0 p-0'>Secure File Sharing: Our Secure File Sharing feature ensures that your files are stored securely within a decentralized data storage network and are encrypted end-to-end, providing an additional layer of protection. Recipients can conveniently access the shared document by simply clicking on the link provided in the email.
</div>`;
const vidioTips =
  'Enable this feature if your signing to be done through a video.';
const signTips =
  'Upload and sign documents on the spot, signer must be present.';
const signOrder = '<div>Turn on and drag to assign the signing order.</div>';
const defaultMessage = `<div className='m-0 p-0'>Check to revert back to custom message.</div>`;
const blastMessage = `<div className='m-0 p-0'>A notification blast to inform users about the promotion or upcoming event.</div>`;
export {
  fileShareOptionTips,
  additionalSecurityTips,
  vidioTips,
  signTips,
  defaultMessage,
  signOrder,
  templateFileTips,
  blastMessage
};
