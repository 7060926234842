import React from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import styles from './style.module.css';
import { CurrencyOptions } from '../../../helpers/maps';

const PlanPriceSet = ({
  pricesFields,
  pricesAppend,
  pricesRemove,
  register,
  errors,
  setValue,
  control
}) => {
  return (
    <div>
      {pricesFields.map((field, index) => (
        <Row key={field.id} className="mt-5">
          <Col md="4" lg="4">
            <Controller
              name={`prices.${index}.currency`}
              control={control}
              // defaultValue={[]}
              // eslint-disable-next-line no-shadow
              render={({ field }) => (
                <Select
                  style={{
                    width: '100%',
                    padding: '15px'
                  }}
                  placeholder="Select currency type"
                  options={CurrencyOptions}
                  bordered={false}
                  className="input_area"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                />
              )}
            />
          </Col>
          <Col md="4" lg="5" sm="12">
            <FloatingLabel controlId={`prices.${index}.amount`} label="Price">
              <Form.Control
                placeholder="Please input internal plan name"
                name={`prices.${index}.amount`}
                type="text"
                autoComplete="off"
                className="input_area body"
                {...register(`prices.${index}.amount`, {
                  required: 'Amount is required'
                })}
              />
              <ErrorMessage
                errors={errors}
                name={`prices.${index}.amount`}
                as={<span className="text-danger d-inline-block ms-2 mt-1" />}
              />
            </FloatingLabel>
          </Col>
          <Col md="4" lg="3" className="pt-5">
            <Form.Check // prettier-ignore
              type="checkbox"
              id="default-0"
              label="Default"
              className="d-inline-block"
              {...register(`prices.${index}.isDefault`)}
            />
            {pricesFields.length > 1 && (
              <i
                aria-hidden="true"
                className="icon_remove fs-4 ms-6"
                onClick={() => pricesRemove(index)}
              />
            )}
            {index === pricesFields.length - 1 && (
              <Button
                className="ms-1 float-end"
                bsPrefix="btn_outline_gray_sm"
                onClick={() => {
                  pricesAppend({
                    amount: null,
                    isDefault: false,
                    currency: null
                  });
                }}
              >
                + Currency
              </Button>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
};
PlanPriceSet.propTypes = {
  pricesFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pricesAppend: PropTypes.func.isRequired,
  pricesRemove: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  errors: PropTypes.shape({}),
  register: PropTypes.func.isRequired,
  control: PropTypes.string.isRequired
};
PlanPriceSet.defaultProps = {
  setValue: null,
  errors: null
};
export default PlanPriceSet;
