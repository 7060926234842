// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_spec_checkbox_btn__2CUr5 {\r\n  background-color:#F0F0F0;\r\n  border-radius: 50px;\r\n  overflow: auto;\r\n  display: inline-block;\r\n  color: #4C4C4C;\r\n  cursor: pointer;\r\n  position: relative;\r\n}\r\n.style_spec_checkbox_btn__2CUr5 label {\r\n  display: block;\r\n}\r\n.style_spec_checkbox_btn__2CUr5 input {\r\n  position: absolute;\r\n  top: -20px;\r\n}\r\n.style_spec_checkbox_btn__2CUr5 span {\r\n  text-align: center;\r\n  display: block;\r\n  padding: 3px 15px;\r\n}\r\n.style_spec_checkbox_btn__2CUr5 input:checked + span, .style_spec_checkbox_btn__2CUr5 input:disabled {\r\n  background-color: #4C4C4C;\r\n  color:#fff;\r\n}", "",{"version":3,"sources":["webpack://src/components/SpecCheckbox/style.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,cAAc;EACd,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":[".spec_checkbox_btn {\r\n  background-color:#F0F0F0;\r\n  border-radius: 50px;\r\n  overflow: auto;\r\n  display: inline-block;\r\n  color: #4C4C4C;\r\n  cursor: pointer;\r\n  position: relative;\r\n}\r\n.spec_checkbox_btn label {\r\n  display: block;\r\n}\r\n.spec_checkbox_btn input {\r\n  position: absolute;\r\n  top: -20px;\r\n}\r\n.spec_checkbox_btn span {\r\n  text-align: center;\r\n  display: block;\r\n  padding: 3px 15px;\r\n}\r\n.spec_checkbox_btn input:checked + span, .spec_checkbox_btn input:disabled {\r\n  background-color: #4C4C4C;\r\n  color:#fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spec_checkbox_btn": "style_spec_checkbox_btn__2CUr5"
};
export default ___CSS_LOADER_EXPORT___;
