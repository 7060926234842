// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_feature_area__3jhoQ {\r\n  background: rgba(246, 246, 246, 0.5) !important;\r\n  border-radius: 15px;\r\n  padding: 40px;\r\n}\r\n.style_feature_item__3hXsK {\r\n  background: #FFFFFF;\r\n  border: 1px solid rgba(76, 76, 76, 0.05);\r\n  border-radius: 15px;\r\n  padding: 17px 20px;\r\n  height: 60px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.style_table_row__EkW_w {\r\n  padding: 20px 0;\r\n  border-bottom: 1px solid #F0F0F0;\r\n}\r\n.style_mb_big__TqXfb {\r\n  margin-bottom: 160px;\r\n}", "",{"version":3,"sources":["webpack://src/components/pages/NewPlan/style.module.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,mBAAmB;EACnB,aAAa;AACf;AACA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,gCAAgC;AAClC;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".feature_area {\r\n  background: rgba(246, 246, 246, 0.5) !important;\r\n  border-radius: 15px;\r\n  padding: 40px;\r\n}\r\n.feature_item {\r\n  background: #FFFFFF;\r\n  border: 1px solid rgba(76, 76, 76, 0.05);\r\n  border-radius: 15px;\r\n  padding: 17px 20px;\r\n  height: 60px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.table_row {\r\n  padding: 20px 0;\r\n  border-bottom: 1px solid #F0F0F0;\r\n}\r\n.mb_big {\r\n  margin-bottom: 160px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature_area": "style_feature_area__3jhoQ",
	"feature_item": "style_feature_item__3hXsK",
	"table_row": "style_table_row__EkW_w",
	"mb_big": "style_mb_big__TqXfb"
};
export default ___CSS_LOADER_EXPORT___;
