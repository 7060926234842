import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import TopBar from '../components/nav/TopBar';
// import Footer from '../components/landingPage/Footer';
import SimpleFooter from '../components/landingPage/SimpleFooter';

const AuthLayout = () => (
  <Container fluid className="p-0">
    <TopBar />
    <Container fluid className="px-0 wrap mt100">
      <Outlet />
    </Container>
    <SimpleFooter />
  </Container>
);

export default AuthLayout;
