import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import MyDropdownBtn from '../DropdownBtn';
import { deletePlans, updatePlans } from '../../../services/plan';
import { BillingOptions, PlanOptions, PlanStatus } from '../../../helpers/maps';

const PlanTable = ({ tableList, getListData }) => {
  const navigate = useNavigate();
  const handleEdit = (data, label) => {
    navigate(`/create-plan/${data.id}`);
  };
  const renderPrices = pricesData => {
    const result = pricesData.map((item, index) => {
      return (
        <div key={index}>
          {item.currency} {item.amount}{' '}
          {item.isDefault ? (
            <span className="ms-2 small">(default)</span>
          ) : null}
        </div>
      );
    });
    return result;
  };
  const handleDelete = async (data, label) => {
    const result = await deletePlans(data.id);
    if (result) {
      toast.success('Delete success');
      await getListData();
    }
  };
  const handleUpdatePlanStatus = async (data, status) => {
    const result = await updatePlans({ planStatus: status }, data.id);
    if (result) {
      toast.success('Update plan status success');
      await getListData();
    }
  };
  return (
    <div className="container-fluid mt-4 px-0">
      <Row className="table_header mx-0 bodybold">
        <Col md="3">Plan Name</Col>
        <Col md="2">Plan Type</Col>
        <Col md="2">Billing Cycle</Col>
        <Col md="2">Price</Col>
        <Col md="1">Status</Col>
        <Col md="2" />
      </Row>
      {tableList.length ? (
        tableList.map(item => {
          return (
            <Row className={`${styles.table_row} mx-0`} key={item.id}>
              <Col md="3">{item.planName}</Col>
              <Col md="2">
                {PlanOptions.filter(i => i.value === item.planType)[0]?.label}
              </Col>
              <Col md="2">
                {
                  BillingOptions.filter(i => i.value === item.billingCycle)[0]
                    ?.label
                }
              </Col>
              <Col md="2">{item.prices ? renderPrices(item.prices) : null}</Col>
              <Col md="1">{PlanStatus[item?.planStatus]}</Col>
              <Col md="2" className="text-center">
                <MyDropdownBtn
                  handleEdit={handleEdit}
                  data={item}
                  handleDelete={handleDelete}
                  handleUpdatePlanStatus={handleUpdatePlanStatus}
                  label="plan"
                />
              </Col>
            </Row>
          );
        })
      ) : (
        <Row>
          <Col className="text-center pt-20 default-color-light bodylarge">
            You don't have any data
          </Col>
        </Row>
      )}
    </div>
  );
};

PlanTable.propTypes = {
  tableList: PropTypes.arrayOf(PropTypes.shape({})),
  getListData: PropTypes.func.isRequired
};
PlanTable.defaultProps = {
  tableList: null
};
export default PlanTable;
