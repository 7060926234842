// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_drop_menu__28iCW {\r\n  box-shadow: 0px 0px 30px rgb(0 0 0 / 5%) !important;\r\n  border-radius: 15px !important;\r\n  padding: 20px 10px !important;\r\n  border: none !important;\r\n}\r\n.style_drop_menu__28iCW > a[class='body mb-2 dropdown-item disabled'] > i {\r\n  opacity: 0.4 !important;\r\n}", "",{"version":3,"sources":["webpack://src/components/Management/DropdownBtn/style.module.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,8BAA8B;EAC9B,6BAA6B;EAC7B,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB","sourcesContent":[".drop_menu {\r\n  box-shadow: 0px 0px 30px rgb(0 0 0 / 5%) !important;\r\n  border-radius: 15px !important;\r\n  padding: 20px 10px !important;\r\n  border: none !important;\r\n}\r\n.drop_menu > a[class='body mb-2 dropdown-item disabled'] > i {\r\n  opacity: 0.4 !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop_menu": "style_drop_menu__28iCW"
};
export default ___CSS_LOADER_EXPORT___;
