/* eslint-disable no-param-reassign */
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Container
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import styles from './index.module.css';
import FiexedHeader from '../../Management/FixedHeader';
import FiexedFooter from '../../Management/FixedFooter';
import MyTooltip from '../../MyTooltip';
import {
  createFeatures,
  getFeatureDetail,
  updateFeatures
} from '../../../services/feature';
import SpecCheckbox from '../../SpecCheckbox';
import { FeatureTypesOptions } from '../../../helpers/maps';

const NewFeature = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      featureName: '',
      featureType: null,
      description: ''
    }
  });
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate('/dashboard');
  };
  const onSubmit = async data => {
    setLoading(true);
    if (id) {
      const params = {
        ...data
      };
      const result = await updateFeatures(params, id);
      if (result) {
        toast.success('Feature updated success');
        handleClose();
      }
      setLoading(false);
      return;
    }

    const params = {
      ...data
    };
    const result = await createFeatures(params);
    if (result) {
      toast.success('Feature created success');
      handleClose();
    }
    setLoading(false);
  };

  const getDetailData = async () => {
    const result = await getFeatureDetail(id);
    if (result) {
      setValue('featureName', result.featureName);
      setValue('description', result.description);
      setValue('featureType', result.featureType);
    }
  };

  useEffect(() => {
    if (id) {
      getDetailData();
    }
  }, []);
  return (
    <div>
      <FiexedHeader handleClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="wrap mt-16">
          <Row>
            <Col className="text-center">
              <h2>{id ? 'Edit Feature' : 'Create Feature'}</h2>
            </Col>
          </Row>

          <div
            className={`${styles['content-width']} mt-6`}
            style={{ minHeight: '550px' }}
          >
            <Row>
              <Col md="12" sm="12" className="mt-6">
                <FloatingLabel controlId="featureName" label="Feature Name">
                  <Form.Control
                    placeholder="  "
                    type="text"
                    autoComplete="off"
                    className={clsx('input_area', 'body', {
                      'is-invalid': errors?.firstName?.message
                    })}
                    // TODO: No prop spreading
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('featureName', {
                      required: 'Feature name is required',
                      maxLength: {
                        value: 100,
                        message:
                          'The maximum length cannot exceed 100 characters'
                      }
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="featureName"
                    as={
                      <span className="text-danger d-inline-block ms-2 mt-1" />
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="mt-6">
                <Controller
                  name="featureType"
                  control={control}
                  // defaultValue={[]}
                  render={({ field }) => (
                    <Select
                      style={{
                        width: '100%',
                        padding: '15px'
                      }}
                      placeholder="Select feature type"
                      options={FeatureTypesOptions}
                      bordered={false}
                      className="input_area"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FloatingLabel
                  controlId=""
                  label="Description"
                  className="mt-5 mb-8 textarea-floating-label"
                >
                  <Form.Control
                    placeholder="Please input description"
                    as="textarea"
                    name="description"
                    rows={5}
                    className="input_area body"
                    style={{ height: '200px' }}
                    // TODO: No prop spreading
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...register('description', {
                      maxLength: {
                        value: 200,
                        message:
                          'The maximum length cannot exceed 200 characters'
                      }
                    })}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </div>
        </Container>
        <FiexedFooter handleClose={handleClose} isDisabled={loading} />
      </form>
    </div>
  );
};

export default NewFeature;
