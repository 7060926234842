/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Container
} from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useKeycloak } from '@react-keycloak/web';
import { toast } from 'react-toastify';
import { Select } from 'antd';
import FiexedHeader from '../../Management/FixedHeader';
import styles from './style.module.css';
import FeatureListDrop from '../../Management/FeatureDroppable';
import FiexedFooter from '../../Management/FixedFooter';
import {
  BillingOptions,
  CurrencyOptions,
  PlanOptions
} from '../../../helpers/maps';
import {
  createPlans,
  getPlanDetail,
  updatePlans
} from '../../../services/plan';
import { getFeatureList } from '../../../services/feature';
import PlanPriceSet from '../../Management/PlanPriceSet';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

let featuresList = [];
const NewPlan = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm({
    defaultValues: {
      planName: '',
      prices: [
        {
          amount: null,
          isDefault: true,
          currency: null
        }
      ]
    }
  });
  const {
    fields: pricesFields,
    append: pricesAppend,
    remove: pricesRemove,
    update: pricesUpdate
  } = useFieldArray({
    control,
    name: 'prices'
  });
  const {
    fields: selectedFeaturesFields,
    append: selectedFeaturesAppend,
    remove: selectedFeaturesRemove,
    update: selectedFeaturesUpdate
  } = useFieldArray({
    control,
    name: 'selectedFeatures'
  });
  const { id } = useParams();
  const [state, setState] = useState([[], []]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/dashboard/2');
  };
  const onSubmit = async data => {
    const features = [...data.selectedFeatures];
    features.map(item => {
      if (item.featureType === '1') {
        item.limit = parseInt(item.limit, 10);
      }
      item.id = item.featureId;
      delete item.featureId;
      delete item.isDeleted;
      delete item.isEnabled;
    });
    const param = { ...data, features };
    delete param.selectedFeatures;
    param.prices.map(item => {
      item.amount = parseFloat(item.amount);
      return null;
    });
    setLoading(true);
    if (id) {
      const result = await updatePlans(param, id);
      if (result) {
        toast.success('Update plan success');
        handleClose();
      }
      setLoading(false);
      return;
    }
    const params = {
      ...param
    };
    const result = await createPlans(params);
    if (result) {
      toast.success('Create plan success');
      handleClose();
    }
    setLoading(false);
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
      if (sInd === 1) {
        setValue('selectedFeatures', items);
      }
    } else {
      const moveResult = move(
        sInd === 0 ? state[sInd] : selectedFeaturesFields,
        dInd === 1 ? selectedFeaturesFields : state[dInd],
        source,
        destination
      );
      const newState = [...state];
      // eslint-disable-next-line prefer-destructuring
      newState[0] = moveResult[0];
      newState[1] = moveResult[1];
      setValue('selectedFeatures', moveResult[1]);
      setState(newState);
    }
  };
  const getFeatureListData = async () => {
    const result = await getFeatureList();
    if (result) {
      result.map(item => {
        delete item.createdAt;
        delete item.isDefault;
        delete item.isDeleted;
        delete item.isEnabled;
        delete item.updatedAt;
        delete item.useCount;
        item.featureId = item.id;
        if (item.featureType === '0') {
          item.permissions = {
            READ: true
          };
        } else if (item.featureType === '2') {
          item.activation = true;
        }
      });
      setState([result, []]);
      featuresList = result;
    }
  };
  const getPlanData = async () => {
    const result = await getPlanDetail(id);
    if (result) {
      setValue('billingCycle', result.billingCycle);
      setValue('internalDescription', result.internalDescription);
      setValue('internalName', result.internalName);
      setValue('planDescription', result.planDescription);
      setValue('planName', result.planName);
      setValue('planType', result.planType);
      setValue('prices', result.prices);
      pricesUpdate(0, {
        amount: result.prices?.[0].amount,
        isDefault: result.prices?.[0].isDefault,
        currency: result.prices?.[0].currency
      });
      const selectedFeatures = result.features;
      selectedFeatures.map(item => {
        item.featureId = item.id;
      });
      const newState = [...state];

      const fIds = selectedFeatures.map(f => f.id);
      const leftFeatures = featuresList.filter(v => !fIds.includes(v.id));
      newState[0] = leftFeatures;
      newState[1] = selectedFeatures;
      setValue('selectedFeatures', selectedFeatures);
      setState(newState);
    }
  };
  const getDataDetail = async () => {
    await getFeatureListData();
    await getPlanData();
  };
  useEffect(() => {
    if (!id) {
      getFeatureListData();
    } else {
      getDataDetail();
    }
  }, []);
  return (
    <div>
      <FiexedHeader handleClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className="wrap mt-16">
          <Row>
            <Col>
              <h2>{id ? 'Edit Plan' : 'Create Plan'}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="bodybig mt-4">
                {id ? 'Edit' : 'Create'} defferent subscription plans.
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="12">
              <FloatingLabel controlId="planName" label="Plan Name">
                <Form.Control
                  placeholder="Please input plan name"
                  name="planName"
                  type="text"
                  autoComplete="off"
                  className="input_area body"
                  {...register('planName', {
                    required: 'Plan name is required',
                    maxLength: {
                      value: 100,
                      message: 'The maximum length cannot exceed 100 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="planName"
                  as={<span className="text-danger d-inline-block ms-2 mt-1" />}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FloatingLabel
                controlId=""
                label="Plan Description"
                className="mt-5 textarea-floating-label"
              >
                <Form.Control
                  placeholder="Please input description"
                  as="textarea"
                  name="planDescription"
                  rows={2}
                  className="input_area body"
                  style={{ height: '120px' }}
                  // TODO: No prop spreading
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('planDescription', {
                    maxLength: {
                      value: 200,
                      message: 'The maximum length cannot exceed 200 characters'
                    }
                  })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md="6" sm="12">
              <Controller
                name="planType"
                control={control}
                rules={{ required: 'Plan type is required' }}
                // defaultValue={[]}
                render={({ field }) => (
                  <Select
                    style={{
                      width: '100%',
                      padding: '15px'
                    }}
                    placeholder="Select plan type"
                    options={PlanOptions}
                    bordered={false}
                    className="input_area"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="planType"
                as={<span className="text-danger d-inline-block ms-2 mt-1" />}
              />
            </Col>
            <Col md="6" sm="12">
              <Controller
                name="billingCycle"
                control={control}
                rules={{ required: 'billingCycle is required' }}
                // defaultValue={[]}
                render={({ field }) => (
                  <Select
                    style={{
                      width: '100%',
                      padding: '15px'
                    }}
                    placeholder="Select billing cycle"
                    options={BillingOptions}
                    bordered={false}
                    className="input_area"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="billingCycle"
                as={<span className="text-danger d-inline-block ms-2 mt-1" />}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md="12">
              <FloatingLabel
                controlId="internalName"
                label="Internal Plan Name"
              >
                <Form.Control
                  placeholder="Please input internal plan name"
                  name="internalName"
                  type="text"
                  autoComplete="off"
                  className="input_area body"
                  {...register('internalName', {
                    required: 'Internal name is required',
                    maxLength: {
                      value: 100,
                      message: 'The maximum length cannot exceed 100 characters'
                    }
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="internalName"
                  as={<span className="text-danger d-inline-block ms-2 mt-1" />}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FloatingLabel
                controlId="internalDescription"
                label="Internal Plan Description"
                className="mt-5 textarea-floating-label"
              >
                <Form.Control
                  placeholder="Please input internal plan description"
                  as="textarea"
                  name="internalDescription"
                  rows={3}
                  className="input_area body"
                  style={{ height: '120px' }}
                  // TODO: No prop spreading
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...register('internalDescription', {
                    maxLength: {
                      value: 200,
                      message: 'The maximum length cannot exceed 200 characters'
                    }
                  })}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <PlanPriceSet
            pricesFields={pricesFields}
            pricesAppend={pricesAppend}
            pricesRemove={pricesRemove}
            register={register}
            errors={errors}
            setValue={setValue}
            control={control}
          />
          <Row>
            <Col>
              <h3 className="mt-8">Feature</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="bodybig mt-4 mb-8">
                Please drag and drop the features required for the Permission
                List.
              </p>
            </Col>
          </Row>
          <Row className="mb-6">
            <Col md={6}>
              <h4 className="subtitle">Feature List</h4>
            </Col>
            <Col md={6}>
              <h4 className="subtitle">Selected Feature List</h4>
            </Col>
          </Row>
          <Row className={`${styles.mb_big}`}>
            <DragDropContext onDragEnd={onDragEnd}>
              <FeatureListDrop data={state[0]} label={0} setState={setState} />
              <FeatureListDrop
                data={selectedFeaturesFields}
                label={1}
                state={state}
                remove={selectedFeaturesRemove}
                update={selectedFeaturesUpdate}
                setState={setState}
                register={register}
                errors={errors}
                control={control}
              />
            </DragDropContext>
          </Row>
        </Container>
        <FiexedFooter handleClose={handleClose} isDisabled={loading} />
      </form>
    </div>
  );
};

export default NewPlan;
