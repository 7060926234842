// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeatureTable_table_row__1U2V2 {\r\n  padding: 20px  0;\r\n  border-bottom: 1px solid #F0F0F0;\r\n}", "",{"version":3,"sources":["webpack://src/components/Management/FeatureTable/index.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,gCAAgC;AAClC","sourcesContent":[".table_row {\r\n  padding: 20px  0;\r\n  border-bottom: 1px solid #F0F0F0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_row": "FeatureTable_table_row__1U2V2"
};
export default ___CSS_LOADER_EXPORT___;
