// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Search_search__13jAz {\r\n  position: relative;\r\n}\r\n\r\n.Search_searchInput__2rV8R {\r\n  padding: 20px 35px;\r\n  grid-gap: 25px;\r\n  gap: 25px;\r\n  width: 100%;\r\n  min-width: 50vw;\r\n  height: 70px;\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);\r\n  border: none;\r\n  border-radius: 15px;\r\n\r\n  font-weight: 400;\r\n  font-size: 16px;\r\n  line-height: 25px;\r\n  color: #9e9e9e;\r\n}\r\n\r\n.Search_icon__5YzcR {\r\n  position: absolute;\r\n  top: 30%;\r\n  right: 20px;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Search/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAS;EAAT,SAAS;EACT,WAAW;EACX,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,4CAA4C;EAC5C,YAAY;EACZ,mBAAmB;;EAEnB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb","sourcesContent":[".search {\r\n  position: relative;\r\n}\r\n\r\n.searchInput {\r\n  padding: 20px 35px;\r\n  gap: 25px;\r\n  width: 100%;\r\n  min-width: 50vw;\r\n  height: 70px;\r\n  background: #ffffff;\r\n  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);\r\n  border: none;\r\n  border-radius: 15px;\r\n\r\n  font-weight: 400;\r\n  font-size: 16px;\r\n  line-height: 25px;\r\n  color: #9e9e9e;\r\n}\r\n\r\n.icon {\r\n  position: absolute;\r\n  top: 30%;\r\n  right: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "Search_search__13jAz",
	"searchInput": "Search_searchInput__2rV8R",
	"icon": "Search_icon__5YzcR"
};
export default ___CSS_LOADER_EXPORT___;
