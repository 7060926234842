// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_header__2tnms {\r\n  height: 78px;\r\n  border-bottom: 1px solid rgba(76, 76, 76, 0.1);\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 9999;\r\n  background: #fff;\r\n}\r\n.style_logo__1D7XM {\r\n  height: 35px;\r\n  margin-top: 21px;\r\n}", "",{"version":3,"sources":["webpack://src/components/Management/FixedHeader/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8CAA8C;EAC9C,gBAAgB;EAChB,MAAM;EACN,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".header {\r\n  height: 78px;\r\n  border-bottom: 1px solid rgba(76, 76, 76, 0.1);\r\n  position: sticky;\r\n  top: 0;\r\n  z-index: 9999;\r\n  background: #fff;\r\n}\r\n.logo {\r\n  height: 35px;\r\n  margin-top: 21px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style_header__2tnms",
	"logo": "style_logo__1D7XM"
};
export default ___CSS_LOADER_EXPORT___;
