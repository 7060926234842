import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './style.module.css';

const FiexedFooter = ({ handleClose, isDisabled, isSubmitting }) => {
  return (
    <Container fluid className={styles.footer}>
      <div className="wrap">
        <Row className="mt-6 text-end">
          <Col>
            <Button bsPrefix="btn_light_gray_md" onClick={handleClose}>
              Cancel
            </Button>
            <input
              type="submit"
              value={isSubmitting ? 'Please wait...' : 'Save'}
              disabled={isDisabled || isSubmitting}
              className={clsx('ms-8', {
                btn_default_md: !isSubmitting,
                btn_light_gray_md: isSubmitting
              })}
            />
          </Col>
        </Row>
      </div>
    </Container>
  );
};
FiexedFooter.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool
};
FiexedFooter.defaultProps = {
  isSubmitting: false
};

export default FiexedFooter;
