const PlanOptions = [
  {
    value: '0',
    label: 'Basic Plan'
  },
  {
    value: '1',
    label: 'Standard Plan'
  },
  {
    value: '2',
    label: 'Premium Plan'
  },
  {
    value: '3',
    label: 'Enterprice Plan'
  }
];
const BillingOptions = [
  {
    value: '0',
    label: 'Monthly'
  },
  {
    value: '1',
    label: 'Annually'
  }
];
const CurrencyOptions = [
  {
    value: 'SGD',
    label: 'Singapore dollar SGD'
  },
  {
    value: 'USD',
    label: 'United States dollar USD'
  },
  {
    value: 'JPY',
    label: 'Japanese yen JPY'
  },
  {
    value: 'CNY',
    label: 'Chinese yuan CNY'
  },
  {
    value: 'MYR',
    label: 'Malaysian ringgit MYR'
  }
];
const FeatureTypesOptions = [
  {
    value: '0',
    label: 'Permission'
  },
  {
    value: '1',
    label: 'UsageLimit'
  },
  {
    value: '2',
    label: 'Activition'
  }
];
const PlanStatus = {
  0: 'Pending',
  1: 'Active',
  2: 'Inactive'
};
const NotificationTypeOptions = [
  {
    value: 0,
    label: 'Alert - All members'
  },
  {
    value: 1,
    label: 'System - All members'
  },
  {
    value: 2,
    label: 'Promotional'
  }
];
const NotificationChannelOptions = [
  {
    value: 0,
    label: 'In-app'
  },
  {
    value: 1,
    label: 'Email'
  },
  // {
  //   value: 2,
  //   label: 'SMS'
  // },
  // {
  //   value: 3,
  //   label: 'Web Push'
  // },
  // {
  //   value: 4,
  //   label: 'Mobile Push'
  // },
  { value: 5, label: 'Top Tips' }
];
const NotificationStatus = {
  0: 'Scheduled',
  1: 'In Progress',
  2: 'Sent'
};

const BlastToOptions = [
  { label: 'Basic Plan', value: 1 },
  { label: 'Standard Plan', value: 2 },
  { label: 'Professional Plan', value: 3 },
  { label: 'Business Pro Plan', value: 4 }
];

export {
  PlanOptions,
  BillingOptions,
  CurrencyOptions,
  FeatureTypesOptions,
  PlanStatus,
  NotificationTypeOptions,
  NotificationChannelOptions,
  NotificationStatus,
  BlastToOptions
};
