import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/logos/logo.svg';

const MobileReminder = ({ show }) => {
  return (
    <Modal show={show} className="" fullscreen>
      <Modal.Body>
        <div className="mt-14 px-12">
          <img alt="TezSign Logo" src={logo} />
        </div>
        <p className="bodybig mt-10 px-12">
          Hi, we noticed that you are using a mobile device or an unsupported
          browser. For the best user experience, we recommend using the TezSign
          platform on a desktop computer.
        </p>
        <p className="bodybig px-12">
          We apologize for any inconvenience and please understand that we are
          working to improve the mobile user experience in our future releases.
        </p>
        <div className="px-12 mt-12 mb-8 bodybig">
          <Button bsPrefix="btn_default_md" href="./">
            Visit TezSign
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
MobileReminder.propTypes = {
  show: PropTypes.bool.isRequired
};
export default MobileReminder;
