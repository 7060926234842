import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';
import { ReactComponent as MagnifyingIcon } from '../../assets/img/icons/magnifying-icon.svg';

const Search = ({ handleSearchInputKeyUp, handleClickSearch }) => {
  const [searchKeyword, setSearchKeyword] = useState('');
  return (
    <div className={styles.search}>
      <input
        className="input_area w_100"
        placeholder="Search"
        onKeyUp={e => {
          handleSearchInputKeyUp(e);
          setSearchKeyword(e.target.value);
        }}
      />
      <MagnifyingIcon
        className={clsx(styles.icon, { clickable: handleClickSearch })}
        onClick={() => {
          if (handleClickSearch) handleClickSearch(searchKeyword);
        }}
      />
    </div>
  );
};
Search.propTypes = {
  handleSearchInputKeyUp: PropTypes.func.isRequired,
  handleClickSearch: PropTypes.func
};

Search.defaultProps = {
  handleClickSearch: null
};

export default Search;
