// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_table_row__2jA6u {\r\n  padding: 20px 0;\r\n  border-bottom: 1px solid #f0f0f0;\r\n}\r\n\r\n.style_tableHeight__3tWwI {\r\n  min-height: 300px;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/pages/Mangemant/Notification/style.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".table_row {\r\n  padding: 20px 0;\r\n  border-bottom: 1px solid #f0f0f0;\r\n}\r\n\r\n.tableHeight {\r\n  min-height: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table_row": "style_table_row__2jA6u",
	"tableHeight": "style_tableHeight__3tWwI"
};
export default ___CSS_LOADER_EXPORT___;
