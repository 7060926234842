import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';

const SpecCheckbox = ({ id, disabled, labelText, name, checked, setValue }) => {
  return (
    <div className={`${styles.spec_checkbox_btn}`} key={id}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          name={name}
          id={id}
          defaultChecked={checked}
          disabled={disabled}
          onChange={e => {
            const value = e.currentTarget.checked;
            setValue(value);
          }}
        />
        <span className="small">{labelText}</span>
      </label>
    </div>
  );
};
SpecCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  setValue: PropTypes.func.isRequired
};
SpecCheckbox.defaultProps = {
  disabled: false,
  checked: false,
  id: null
};
export default SpecCheckbox;
