import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const FullScreenModule = () => (
  <Container fluid className="px-0">
    <Outlet />
  </Container>
);

export default FullScreenModule;
