import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Search from '../../../Search';
import styles from './style.module.css';
import PlanTable from '../../../Management/PlanTable';
import { getPlanList, searchPlansByName } from '../../../../services/plan';
import NewLoader from '../../../common/NewLoader';

const Plan = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const searchData = async value => {
    const result = await searchPlansByName(value);
    if (result) {
      setTableData(result);
    }
  };
  const handleSearchInputKeyUp = event => {
    const { value } = event.target;
    searchData(value);
  };
  const handleCreatePlan = () => {
    navigate('/create-plan');
  };
  const getListData = async () => {
    setLoading(true);
    const result = await getPlanList();
    if (result) {
      const newData = result.sort((a, b) => {
        return b.updatedAt < a.updatedAt ? -1 : 1;
      });
      setTableData(newData);
      setLoading(false);
    }
  };
  const debouceSearch = _.debounce(handleSearchInputKeyUp, 500);
  useEffect(() => {
    getListData();
  }, []);
  return (
    <div>
      <Row className="mb-8">
        <Col>
          <h3 className="tem_header">Plan</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="bodybig">
          <p>Create and manage different subscription plans.</p>
        </Col>
      </Row>
      <Row>
        <Col lg={7} md={12} className="mt-6">
          <Button bsPrefix="btn_default_xl" onClick={handleCreatePlan}>
            Create Plan
          </Button>
        </Col>
        <Col lg={5} md={12} className="mt-6 mb-10">
          <Search handleSearchInputKeyUp={debouceSearch} />
        </Col>
      </Row>
      {loading ? (
        <NewLoader className="mt-20" />
      ) : (
        <PlanTable tableList={tableData} getListData={getListData} />
      )}
    </div>
  );
};
export default Plan;
