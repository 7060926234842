import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import { Navigate } from 'react-router-dom';
import MobileReminder from '../components/MobileReminder';

const Private = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const { keycloak } = useKeycloak();
  const [isMobile, setIsMobile] = useState(false);

  function detectWindowSize() {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }
  // return children;
  useEffect(() => {
    if (keycloak.authenticated) {
      setIsLoggedIn(true);
    }
    if (keycloak?.authenticated === false) {
      setIsLoggedIn(false);
    }
  });

  useEffect(() => {
    detectWindowSize();
    window.addEventListener('resize', detectWindowSize);
    return () => {
      window.removeEventListener('resize', detectWindowSize);
    };
  }, []);

  if (isLoggedIn) {
    if (isMobile) {
      return <MobileReminder show />;
    }
    return children;
  }
  if (isLoggedIn === false) {
    return <Navigate to="/" replace />;
  }

  // if (keycloak.authenticated) {
  //   return children;
  // }
  // return <Navigate to="/" replace />;

  // return isLoggedIn ? children : null;
  // return children;
  // if (key === null) {
  //   return <Navigate to="/login" replace />;
  // }

  // if (auth.user?.verifiedAccount) {
  //   return children;
  // }

  // if (auth.user?.verifiedAccount === false) {
  //   return <Navigate to={`/verify-account/${key}`} replace />;
  // }

  return '';
};

Private.propTypes = {
  children: PropTypes.node
};

Private.defaultProps = {
  children: null
};

export default Private;
