/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Form,
  FloatingLabel,
  Button,
  Container
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import katex from 'katex';
import clsx from 'clsx';
import 'katex/dist/katex.min.css';
import { toast } from 'react-toastify';
import { Select, DatePicker } from 'antd';
import dayjs from 'dayjs';
import FiexedHeader from '../../Management/FixedHeader';
import styles from './style.module.css';
import FiexedFooter from '../../Management/FixedFooter';
import {
  NotificationTypeOptions,
  NotificationChannelOptions,
  BlastToOptions
} from '../../../helpers/maps';
import {
  getNotificationDetail,
  createNotification,
  updateNotification
} from '../../../services/notification';
import NewLoader from '../../common/NewLoader';
import {
  SUNEDITORBUTTONLIST,
  TEMPLATES,
  maxLengthAllowed
} from '../../../constants';
import { blastMessage } from '../../../helpers/tooltipContent';
import MyTooltip from '../../MyTooltip';

const mediaFileSizeLimit = process.env.REACT_APP_MEDIA_FILE_SIZE_LIMIT;

const NewNotification = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    clearErrors,
    watch
  } = useForm({
    defaultValues: {
      type: 0,
      channel: 0
    }
  });
  const { action, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const mediaFileRef = useRef();

  const handleClose = () => {
    navigate('/dashboard/3');
  };
  const onSubmit = async data => {
    setIsSubmitting(true);
    const sendData = { ...data };
    sendData.picUri = sendData.mediaFile ? sendData.mediaFile.base64 : '';
    sendData.userGroup = sendData.userGroup.map(b => {
      return Number(b);
    });
    if (sendData.userGroup.includes(0)) {
      sendData.userGroup = [0];
    }
    delete sendData.mediaFile;
    if (sendData.channel === 1) {
      sendData.body = `<div style='width:auto; max-width: 600px; margin: 0 auto; padding: 50px 30px; text-align: center;background-color: white;font-family: 'Manrope'; color: #4C4C4C; letter-spacing: 0.03em;'>${sendData.emailBody}</div>`;
    }
    if (sendData.channel === 5) {
      delete sendData.subject;
    }

    if (id) {
      const result = await updateNotification(sendData, id);
      if (result) {
        toast.success('Update notification success');
        handleClose();
      }
      setIsLoading(false);
      return;
    }
    const result = await createNotification(sendData);
    if (result) {
      toast.success('Notification is created successfully');
      handleClose();
    }
    setIsSubmitting(false);
  };

  const handleUploadMedia = () => {
    mediaFileRef.current.click();
  };

  const handleMediaFileChange = (e, onChange) => {
    const mediaFile = e.target.files[0];
    const filesSize = mediaFile.size / (1024 * 1024);
    if (filesSize > mediaFileSizeLimit) {
      setError('mediaFile', {
        type: 'custom',
        message: `Media file size cannot exceed ${mediaFileSizeLimit}M`
      });
    } else {
      onChange(mediaFile);
      clearErrors('mediaFile');
      toast.info('File is uploading', {
        autoClose: 3000
      });
      const reader = new FileReader();
      reader.readAsDataURL(mediaFile);
      reader.onload = () => {
        onChange({
          base64: reader.result,
          size: mediaFile.size,
          path: mediaFile.name,
          type: mediaFile.type
        });
        setValue('picUri', reader.result);
      };
    }
  };

  const getNotificationById = async () => {
    const result = await getNotificationDetail(id);
    if (result) {
      setValue('subject', result.subject);
      setValue('body', result.body);
      if (result.channel === 1) {
        setValue('emailBody', result.body);
      }
      setValue('type', result.type);
      setValue('channel', result.channel);
      setValue(
        'userGroup',
        result.userGroup ? result.userGroup.map(String) : []
      );
      setValue('actionBtnName', result.actionBtnName);
      setValue('picUri', result.mediaUrl);
      setValue('actionUrl', result.actionUrl);
      setValue('sendAt', dayjs(result.sendAt));
      setValue('expiredAt', dayjs(result.expiredAt));
      setIsLoading(false);
    }
  };

  const getTitle = () => {
    switch (action) {
      case 'edit':
        return 'Edit Notification';
      case 'create':
        return 'Create Notification';
      case 'view':
        return 'View Notification';
      default:
        return '';
    }
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };

  useEffect(() => {
    if (id) {
      getNotificationById();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <div>
      <FiexedHeader handleClose={handleClose} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={`wrap mt-16 ${styles.content}`}>
          {isLoading ? (
            <NewLoader className="mt-20" />
          ) : (
            <>
              <Row>
                <Col>
                  <h2>{getTitle()}</h2>
                </Col>
              </Row>
              <Row className="mt-5">
                {/* Notification Channel */}
                <Col md="6" sm="12">
                  <Controller
                    name="channel"
                    control={control}
                    rules={{ required: 'Notification channel is required' }}
                    // defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        style={{
                          width: '100%',
                          padding: '15px'
                        }}
                        placeholder="Select notification channel"
                        options={NotificationChannelOptions}
                        bordered={false}
                        className="input_area"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        disabled={action === 'view' || isSubmitting}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="channel"
                    as={
                      <span className="text-danger d-inline-block ms-2 mt-1" />
                    }
                  />
                </Col>
                {/* Notification Type */}
                <Col md="6" sm="12">
                  <Controller
                    name="type"
                    control={control}
                    rules={{ required: 'Notification type is required' }}
                    // defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        style={{
                          width: '100%',
                          padding: '15px'
                        }}
                        placeholder="Select notification type"
                        options={NotificationTypeOptions}
                        bordered={false}
                        className="input_area"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        disabled={action === 'view' || isSubmitting}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="type"
                    as={
                      <span className="text-danger d-inline-block ms-2 mt-1" />
                    }
                  />
                </Col>
              </Row>
              <Row>
                {/* Publish Date */}
                <Col
                  md="6"
                  // xl={watch('channel') !== 5 ? 3 : 6}
                  className="mt-4"
                >
                  <Controller
                    control={control}
                    name="sendAt"
                    rules={{ required: 'Publish date is required' }}
                    defaultValue={dayjs()}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        onChange={date => {
                          onChange(date);
                        }}
                        placeholder="Publish Date"
                        format="DD/MM/YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                        value={value}
                        showNow={false}
                        allowClear={false}
                        className="custom-date-input me-1 input_area w-100"
                        suffixIcon={null}
                        disabledDate={current =>
                          current.isBefore(dayjs().subtract(1, 'day'))
                        }
                        disabled={action === 'view' || isSubmitting}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="sendAt"
                    as={
                      <span className="text-danger d-inline-block ms-2 mt-1" />
                    }
                  />
                </Col>
                {/* Expiry Date */}
                {watch('channel') === 5 && (
                  <Col md="6" className="mt-4">
                    <Controller
                      control={control}
                      name="expiredAt"
                      rules={{ required: 'Expiration date is required' }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          onChange={date => {
                            onChange(date);
                          }}
                          placeholder="Expiration Date"
                          format="DD/MM/YYYY HH:mm"
                          showTime={{ format: 'HH:mm' }}
                          value={value}
                          showNow={false}
                          allowClear={false}
                          className="custom-date-input me-1 input_area w-100"
                          suffixIcon={null}
                          disabledDate={current =>
                            current.isBefore(dayjs().subtract(1, 'day'))
                          }
                          disabled={action === 'view' || isSubmitting}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="expiredAt"
                      as={
                        <span className="text-danger d-inline-block ms-2 mt-1" />
                      }
                    />
                  </Col>
                )}
                {/* Blast To */}
                <Col
                  md="12"
                  // xl={watch('channel') !== 5 ? 9 : 12}
                  className="mt-4"
                >
                  <div className={clsx(styles.blastTo)}>
                    <Form.Label
                      className={clsx(
                        'bodybold mb-0',
                        styles.checkboxGroupLabel
                      )}
                    >
                      Blast to
                      <MyTooltip position="top" content={blastMessage} />
                    </Form.Label>
                    <div
                      key="inline-checkbox"
                      className={clsx(styles.checkboxGroup)}
                    >
                      {[{ label: 'All', value: 0 }, ...BlastToOptions].map(
                        (opt, index) => (
                          <Form.Check
                            id={index + 1}
                            key={opt.value}
                            inline
                            type="checkbox"
                            label={opt.label}
                            value={opt.value}
                            disabled={action === 'view' || isSubmitting}
                            className="mb-0"
                            {...register('userGroup', {
                              required: 'Blast to is required'
                            })}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <ErrorMessage
                    errors={errors}
                    name="userGroup"
                    as={
                      <span className="text-danger d-inline-block ms-2 mt-1" />
                    }
                  />
                </Col>
              </Row>
              {/* Media Image */}
              <Row className="mt-4">
                {watch('channel') === 0 && (
                  <Col md="12" lg="5">
                    {action !== 'view' && (
                      <>
                        <div className="d-flex ms-2 ">
                          Media Image
                          <Controller
                            render={({ field: { onChange } }) => (
                              <Button
                                className="btn_outline_gray_sm btn_square_md ms-3 d-flex align-items-center"
                                onClick={handleUploadMedia}
                              >
                                Upload +
                                <input
                                  id="media-file-id"
                                  type="file"
                                  ref={mediaFileRef}
                                  onChange={e => {
                                    handleMediaFileChange(e, onChange);
                                    mediaFileRef.current.value = '';
                                  }}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  disabled={action === 'view' || isSubmitting}
                                />
                              </Button>
                            )}
                            name="mediaFile"
                            control={control}
                            // rules={{ required: 'Media Image is required' }}
                          />
                          {watch('mediaFile') && (
                            <span className="ms-3">
                              Uploaded: {watch('mediaFile').path}
                            </span>
                          )}
                        </div>
                        <ErrorMessage
                          errors={errors}
                          name="mediaFile"
                          as={
                            <span className="text-danger d-inline-block ms-2 mt-1" />
                          }
                        />
                      </>
                    )}
                    {watch('picUri') && (
                      <>
                        {action === 'view' && (
                          <div className="ms-2 mt-4">Media Image</div>
                        )}
                        <img
                          className="ms-2 mt-1"
                          src={watch('picUri')}
                          width="400"
                          alt=""
                        />
                      </>
                    )}
                  </Col>
                )}
              </Row>
              {/* Subject */}
              {[0, 1].includes(watch('channel')) && (
                <Row className="mt-4">
                  <Col md="12">
                    <FloatingLabel
                      controlId="subject"
                      label={`Subject (Max ${
                        maxLengthAllowed[watch('channel')].subject
                      } Characters)`}
                    >
                      <Form.Control
                        placeholder="Please input subject"
                        name="subject"
                        type="text"
                        autoComplete="off"
                        className="input_area body"
                        {...register('subject', {
                          required: 'Subject is required',
                          maxLength: {
                            value: maxLengthAllowed[watch('channel')].subject,
                            message: `The maximum length cannot exceed ${
                              maxLengthAllowed[watch('channel')].subject
                            } characters`
                          }
                        })}
                        disabled={action === 'view' || isSubmitting}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="subject"
                        as={
                          <span className="text-danger d-inline-block ms-2 mt-1" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}
              {/* body */}
              {watch('channel') === 1 ? (
                <Row className="mt-5">
                  <Col md="12">
                    <Controller
                      control={control}
                      name="emailBody"
                      rules={{ required: 'Content is required' }}
                      render={({ field: { onChange, value } }) => (
                        <SunEditor
                          lang="en"
                          placeholder="Please input content here..."
                          onChange={body => {
                            onChange(body);
                          }}
                          setContents={value}
                          setOptions={{
                            height: 500,
                            katex,
                            buttonList: SUNEDITORBUTTONLIST,
                            templates: TEMPLATES,
                            className: 'email-editor',
                            addTagsWhitelist: '*',
                            attributesWhitelist: { all: '*' },
                            font: [
                              'Manrope',
                              'Arial',
                              'Comic Sans MS',
                              'Open Sans',
                              'Courier New',
                              'Impact',
                              'Georgia',
                              'tahoma',
                              'Trebuchet MS',
                              'Verdana'
                            ],
                            previewTemplate:
                              "<div style='width:auto; max-width: 600px; margin: 0 auto;background-color: white;font-family: 'Manrope'; color: #4C4C4C; letter-spacing: 0.03em;'>{{contents}}</div>"
                          }}
                          disable={action === 'view' || isSubmitting}
                        />
                      )}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="mt-5">
                  <Col md="12">
                    <FloatingLabel
                      controlId="body"
                      label="Content"
                      className="textarea-floating-label"
                      disabled={action === 'view' || isSubmitting}
                    >
                      <Form.Control
                        placeholder="Please input content"
                        as="textarea"
                        name="body"
                        rows={5}
                        className="input_area body"
                        style={{ height: '200px' }}
                        // TODO: No prop spreading
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...register('body', {
                          required: 'Content is required',
                          maxLength: {
                            value: maxLengthAllowed[watch('channel')].body,
                            message: `The maximum length cannot exceed ${
                              maxLengthAllowed[watch('channel')].body
                            } characters`
                          }
                        })}
                        disabled={action === 'view' || isSubmitting}
                      />
                      <span className="float-end me-1 mt-2">
                        Characters remaining:{' '}
                        {maxLengthAllowed[watch('channel')].body -
                          (watch('body')?.length ?? 0)}
                      </span>
                      <ErrorMessage
                        errors={errors}
                        name="body"
                        as={
                          <span className="text-danger d-inline-block ms-2 mt-1" />
                        }
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              )}
              {[0, 5].includes(watch('channel')) && (
                <>
                  {/* Action url */}
                  <Row className="mt-4">
                    <Col md="6">
                      <FloatingLabel
                        controlId="actionBtnName"
                        label="Action Button Naming"
                      >
                        <Form.Control
                          placeholder="Please input action button naming"
                          name="actionBtnName"
                          type="text"
                          autoComplete="off"
                          className="input_area body"
                          {...register('actionBtnName', {
                            required: watch('actionUrl')
                              ? 'Action Button naming is required'
                              : false,
                            maxLength: {
                              value: 15,
                              message:
                                'The maximum length cannot exceed 15 characters'
                            }
                          })}
                          disabled={action === 'view' || isSubmitting}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="actionBtnName"
                          as={
                            <span className="text-danger d-inline-block ms-2 mt-1" />
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col md="6">
                      <FloatingLabel controlId="actionUrl" label="Action URL">
                        <Form.Control
                          placeholder="Please input action url"
                          name="actionUrl"
                          type="text"
                          autoComplete="off"
                          className="input_area body"
                          {...register('actionUrl', {
                            required: watch('actionBtnName')
                              ? 'Action Url is required'
                              : false,
                            maxLength: {
                              value: 100,
                              message:
                                'The maximum length cannot exceed 100 characters'
                            }
                          })}
                          disabled={action === 'view' || isSubmitting}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="actionUrl"
                          as={
                            <span className="text-danger d-inline-block ms-2 mt-1" />
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
        {action !== 'view' && (
          <FiexedFooter
            handleClose={handleClose}
            isDisabled={isLoading}
            isSubmitting={isSubmitting}
          />
        )}
      </form>
    </div>
  );
};

export default NewNotification;
