import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Col, FloatingLabel, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Select, Switch } from 'antd';
import { ErrorMessage } from '@hookform/error-message';
import { Controller } from 'react-hook-form';
import MyTooltip from '../../MyTooltip';
import SpecCheckbox from '../../SpecCheckbox';
import styles from './style.module.css';

const FeatureListDrop = ({
  data,
  state,
  remove,
  update,
  label,
  setState,
  register,
  errors,
  control
}) => {
  return (
    <Droppable key={label} droppableId={`${label}`}>
      {(provided, snapshot) => (
        <Col
          md="6"
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.droppableProps}
        >
          <div className={styles.feature_area}>
            {data?.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(subprovided, subsnapshot) => (
                  <div
                    ref={subprovided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...subprovided.draggableProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...subprovided.draggableProps}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...subprovided.dragHandleProps}
                    style={subprovided.draggableProps.style}
                  >
                    <div className={`${styles.feature_item} mb-4`}>
                      <span>
                        <i className="icon_list me-3" />
                        {item.featureName}
                      </span>
                      {label === 0 && (
                        <MyTooltip position="top" content={item.description} />
                      )}
                      {label === 1 && (
                        <div>
                          {item.featureType === '0' && (
                            <>
                              <div className="me-2 d-inline-block">
                                <SpecCheckbox
                                  name="read"
                                  disabled
                                  labelText="Read"
                                  checked
                                  setValue={value => {
                                    const obj = { ...item };
                                    obj.permissions.READ = value;
                                    update(index, obj);
                                  }}
                                />
                              </div>
                              <div className="d-inline-block">
                                <SpecCheckbox
                                  name="write"
                                  labelText="Write"
                                  checked={item?.permissions?.WRITE}
                                  setValue={value => {
                                    const obj = { ...item };
                                    obj.permissions.WRITE = value;
                                    update(index, obj);
                                  }}
                                />
                              </div>
                            </>
                          )}
                          {item.featureType === '2' && (
                            <Controller
                              control={control}
                              name={`selectedFeatures.${index}.activation`}
                              render={({ field }) => (
                                <Switch
                                  checkedChildren="On"
                                  unCheckedChildren="Off"
                                  defaultChecked={item?.activation || 'On'}
                                />
                              )}
                            />
                          )}
                          {item.featureType === '1' && (
                            <>
                              <div
                                className="d-inline-block me-3 align-top"
                                style={{ width: '75px' }}
                                key={item.id}
                              >
                                <Form.Group
                                  controlId={`selectedFeatures.${index}.limit`}
                                  label="Limit"
                                >
                                  <Form.Control
                                    placeholder="Limit"
                                    name={`selectedFeatures.${index}.limit`}
                                    type="text"
                                    autoComplete="off"
                                    defaultValue={item?.limit}
                                    className={`${styles.input_limit} small`}
                                    {...register(
                                      `selectedFeatures.${index}.limit`,
                                      {
                                        required: 'Required'
                                      }
                                    )}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`selectedFeatures.${index}.limit`}
                                    as={
                                      <span className="text-danger d-inline-block mt-0" />
                                    }
                                  />
                                </Form.Group>
                              </div>

                              <Controller
                                control={control}
                                name={`selectedFeatures.${index}.resetFrequency`}
                                render={({ field }) => (
                                  <Select
                                    placeholder="Set Frequency"
                                    style={{ width: 95 }}
                                    // name="resetFrequency"
                                    defaultValue={item?.resetFrequency}
                                    className="small align-top"
                                    size="small"
                                    options={[
                                      { value: 'Monthly', label: 'Monthly' },
                                      { value: 'Annually', label: 'Annually' }
                                    ]}
                                    {...field}
                                  />
                                )}
                              />
                            </>
                          )}
                          <div className="align-top d-inline-block">
                            <MyTooltip
                              position="top"
                              content={item.description}
                            />
                          </div>
                          <i
                            aria-hidden
                            className="icon_remove ms-2 align-top"
                            onClick={() => {
                              const newState = [...state];
                              const curDelete = newState[label].splice(
                                index,
                                1
                              );
                              newState[0].push(curDelete[0]);
                              setState(newState);
                              remove(index);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </Col>
      )}
    </Droppable>
  );
};
FeatureListDrop.propTypes = {
  state: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  label: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
  register: PropTypes.func,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  remove: PropTypes.func,
  update: PropTypes.func
};
FeatureListDrop.defaultProps = {
  state: [[], []],
  register: null,
  errors: null,
  control: null,
  data: null,
  remove: null,
  update: null
};
export default FeatureListDrop;
