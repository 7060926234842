// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_feature_item__2qtz6 {\r\n  background: #FFFFFF;\r\n  border: 1px solid rgba(76, 76, 76, 0.05);\r\n  border-radius: 15px;\r\n  padding: 17px 20px;\r\n  height: 60px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.style_feature_area__1o29B {\r\n  background: rgba(246, 246, 246, 0.5) !important;\r\n  border-radius: 15px;\r\n  padding: 40px;\r\n  min-height: 300px;\r\n}\r\n.style_input_limit__oPatd {\r\n  width: 60px;\r\n  border-radius: 5px !important;\r\n  padding: 2px 5px;\r\n  border: 1px solid rgba(76, 76, 76, 0.20);\r\n  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);\r\n  height: 24px;\r\n}", "",{"version":3,"sources":["webpack://src/components/Management/FeatureDroppable/style.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,+CAA+C;EAC/C,mBAAmB;EACnB,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,6BAA6B;EAC7B,gBAAgB;EAChB,wCAAwC;EACxC,gDAAgD;EAChD,YAAY;AACd","sourcesContent":[".feature_item {\r\n  background: #FFFFFF;\r\n  border: 1px solid rgba(76, 76, 76, 0.05);\r\n  border-radius: 15px;\r\n  padding: 17px 20px;\r\n  height: 60px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n.feature_area {\r\n  background: rgba(246, 246, 246, 0.5) !important;\r\n  border-radius: 15px;\r\n  padding: 40px;\r\n  min-height: 300px;\r\n}\r\n.input_limit {\r\n  width: 60px;\r\n  border-radius: 5px !important;\r\n  padding: 2px 5px;\r\n  border: 1px solid rgba(76, 76, 76, 0.20);\r\n  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);\r\n  height: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feature_item": "style_feature_item__2qtz6",
	"feature_area": "style_feature_area__1o29B",
	"input_limit": "style_input_limit__oPatd"
};
export default ___CSS_LOADER_EXPORT___;
