// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewFeature_content-wrap__9yzsv {\r\n  background: rgba(246, 246, 246, 0.5);\r\n  border-radius: 15px;\r\n  padding: 50px 40px;\r\n}\r\n.NewFeature_content-width__3G_4R {\r\n  max-width: 860px;\r\n  margin: 0 auto; \r\n}\r\n.NewFeature_mb_big__3dnrb {\r\n  margin-bottom: 160px;\r\n}", "",{"version":3,"sources":["webpack://src/components/pages/NewFeature/index.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".content-wrap {\r\n  background: rgba(246, 246, 246, 0.5);\r\n  border-radius: 15px;\r\n  padding: 50px 40px;\r\n}\r\n.content-width {\r\n  max-width: 860px;\r\n  margin: 0 auto; \r\n}\r\n.mb_big {\r\n  margin-bottom: 160px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-wrap": "NewFeature_content-wrap__9yzsv",
	"content-width": "NewFeature_content-width__3G_4R",
	"mb_big": "NewFeature_mb_big__3dnrb"
};
export default ___CSS_LOADER_EXPORT___;
