// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_footer__1ahWL {\r\n  height: 88px;\r\n  border-top: 1px solid rgba(76, 76, 76, 0.1);\r\n  position: fixed;\r\n  bottom: 0;\r\n  z-index: 99;\r\n  background: #fff;\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/Management/FixedFooter/style.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2CAA2C;EAC3C,eAAe;EACf,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".footer {\r\n  height: 88px;\r\n  border-top: 1px solid rgba(76, 76, 76, 0.1);\r\n  position: fixed;\r\n  bottom: 0;\r\n  z-index: 99;\r\n  background: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "style_footer__1ahWL"
};
export default ___CSS_LOADER_EXPORT___;
