import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import logo from '../../../assets/logos/website_logo.png';
import styles from './style.module.css';

const FiexedHeader = ({ handleClose }) => {
  return (
    <Container fluid className={styles.header}>
      <div className="wrap">
        <Row>
          <Col md="10">
            <div className={styles.topBar}>
              <img src={logo} alt="logo" className={styles.logo} />
            </div>
          </Col>
          <Col md="2" className="text-end">
            <i className="icon_close mt-6" aria-hidden onClick={handleClose} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};
FiexedHeader.propTypes = {
  handleClose: PropTypes.func.isRequired
};
export default FiexedHeader;
