/** refer
 * https://www.keycloak.org/docs/latest/securing_apps/#javascript-adapter-reference
 */
import Keycloak from 'keycloak-js';

const keycloakServer = process.env.REACT_APP_KEYCLOAK_SERVER;
const generateKeycloak = (
  realm = 'tezsign-superadmin',
  url = keycloakServer
) => {
  const instance = new Keycloak({
    url,
    realm,
    clientId: 'tezsign-frontend'
  });
  return instance;
};
/**
 *
 * @param {Keycloak instance} kc
 */
const doLogout = kc => {
  localStorage.removeItem('realm');
  kc.logout();
};
const getToken = kc => kc.token;
const isLoggedIn = kc => !!kc.token;
const getUsername = kc => kc.tokenParsed?.name;
const getUserEmail = kc => kc.tokenParsed?.email;
const isAdmin = kc => {
  const roles = kc.tokenParsed?.resource_access?.['tezsign-app']?.roles;
  if (roles) {
    const result = roles.includes('manage-users') || roles.includes('user');
    return result;
  }
  return false;
};
const hasTemplateRight = kc => {
  const roles = kc.tokenParsed?.resource_access?.['tezsign-app']?.roles;
  if (roles) {
    const result = roles.includes('template');
    return result;
  }
  return false;
};
const getSubscriptionInfo = kc => {
  const subscriptionIds = kc.tokenParsed?.subscriptionIds;
  const subscriberIds = kc.tokenParsed?.subscriberIds;
  return {
    subscriberIds,
    subscriptionIds
  };
};
const hasRole = (kc, roles) => roles.some(role => kc.hasRealmRole(role));
const goAccountConsole = kc => kc.accountManagement();
const getAccountUrl = kc => {
  const url = kc.createAccountUrl({
    redirectUri: window.location.href
  });
  window.open(url, '_self');
};
const getPasswordUrl = kc => {
  const url = kc.createAccountUrl({
    redirectUri: window.location.href
  });
  const arr = url?.split('?');
  const passwordUrl = `${arr[0]}/password?${arr[1]}`;
  window.open(passwordUrl, '_self');
};
const goUpdatePassword = kc => {
  const accountUrl = kc.createAccountUrl({
    redirectUri: window.location.href
  });
  const arr = accountUrl?.split('?');
  const passwordUrl = `${arr[0]}/password?${arr[1]}`;
  window.open(passwordUrl, '_self');
};
const goAdminConsole = kc => {
  const url = `${keycloakServer}admin/${kc.realm}/console`;
  window.open(url, '_self');
};
const updateToken = (kc, successCallback) =>
  kc.updateToken(5).then(successCallback);

const isNusUser = kc => {
  return kc.realm === 'nus' && kc.authenticated === true;
};
export {
  generateKeycloak,
  doLogout,
  getToken,
  isLoggedIn,
  getUsername,
  hasRole,
  goAccountConsole,
  goAdminConsole,
  goUpdatePassword,
  updateToken,
  isAdmin,
  getUserEmail,
  hasTemplateRight,
  getAccountUrl,
  isNusUser,
  getSubscriptionInfo
};
