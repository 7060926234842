// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrap__10tGS {\r\n  text-align: center;\r\n}\r\n.styles_loop__ZcCCY {\r\n  width: 50px;\r\n  height: auto;\r\n  margin: 0 auto;\r\n}\r\n\r\n.styles_path__PS9qh {\r\n  stroke-dasharray: 1000;\r\n  stroke-dashoffset: 1000;\r\n  animation: styles_dash__1jIMy 5s linear alternate infinite;\r\n}\r\n\r\nbody {\r\n  background: #fff;\r\n  display: grid;\r\n  place-items: center;\r\n  width: 100%;\r\n}\r\n\r\n@keyframes styles_dash__1jIMy {\r\n  from {\r\n    stroke-dashoffset: 1000;\r\n  }\r\n  to {\r\n    stroke-dashoffset: 0;\r\n  }\r\n}\r\n\r\n.styles_blink_me__1CVYT {\r\n  color: #4c4c4c;\r\n  padding: 5px;\r\n  animation: styles_blinker__mPFI7 1s linear infinite;\r\n}\r\n\r\n@keyframes styles_blinker__mPFI7 {\r\n  50% {\r\n    opacity: 0;\r\n  }\r\n}\r\n", "",{"version":3,"sources":["webpack://src/components/common/NewLoader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,0DAA4C;AAC9C;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,oBAAoB;EACtB;AACF;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,mDAAqC;AACvC;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".wrap {\r\n  text-align: center;\r\n}\r\n.loop {\r\n  width: 50px;\r\n  height: auto;\r\n  margin: 0 auto;\r\n}\r\n\r\n.path {\r\n  stroke-dasharray: 1000;\r\n  stroke-dashoffset: 1000;\r\n  animation: dash 5s linear alternate infinite;\r\n}\r\n\r\nbody {\r\n  background: #fff;\r\n  display: grid;\r\n  place-items: center;\r\n  width: 100%;\r\n}\r\n\r\n@keyframes dash {\r\n  from {\r\n    stroke-dashoffset: 1000;\r\n  }\r\n  to {\r\n    stroke-dashoffset: 0;\r\n  }\r\n}\r\n\r\n.blink_me {\r\n  color: #4c4c4c;\r\n  padding: 5px;\r\n  animation: blinker 1s linear infinite;\r\n}\r\n\r\n@keyframes blinker {\r\n  50% {\r\n    opacity: 0;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "styles_wrap__10tGS",
	"loop": "styles_loop__ZcCCY",
	"path": "styles_path__PS9qh",
	"dash": "styles_dash__1jIMy",
	"blink_me": "styles_blink_me__1CVYT",
	"blinker": "styles_blinker__mPFI7"
};
export default ___CSS_LOADER_EXPORT___;
