import React from 'react';
import heroBanner from '../assets/img/land_hero_banner.png';
import trustIcon from '../assets/img/land_trusted.svg';
import secureIcon from '../assets/img/land_secure.svg';
import easierIcon from '../assets/img/land_easier.svg';
import productsBg1 from '../assets/img/vecteezy_1.png';
import productsBg2 from '../assets/img/vecteezy_2.png';
import productsBg3 from '../assets/img/vecteezy_3.png';
import partnersSbip from '../assets/partners/sbip_logo.png';
import partnersNus from '../assets/partners/nus_logo.jpg';
import partnersNusdbsys from '../assets/partners/nusdbsystem_logo.png';
import efficientIcon from '../assets/img/icons/efficient-icon.svg';

export const content = {
  home: {
    path: 'home',
    image: heroBanner,
    slogan: 'A Digital Signing Platform for Everyone'
  },
  about: {
    path: 'about',
    header: 'About',
    content:
      'Convenience Access, approve and sign documents globally, at your own time.',
    items: [
      {
        id: 'trust',
        image: trustIcon,
        header: 'Trusted',
        content:
          'Use of blockchain technology for verification of identity, document history for transparency and trust.'
      },
      {
        id: 'secure',
        image: secureIcon,
        header: 'Secure',
        content:
          'Securely manages users’ private key and associated credentials.'
      },
      {
        id: 'efficient',
        image: efficientIcon,
        header: 'Efficient',
        content:
          'Speed up traditional processes by allowing recipients to receive documents directly.'
      },
      {
        id: 'easier',
        image: easierIcon,
        header: 'Convenient',
        content:
          'Provides easy-to-use interface for document owner and signers to approve and sign documents anywhere, anytime.'
      }
    ]
  },
  products: {
    path: 'products',
    header: 'Products',
    content:
      'We provide three tools to facilitate trusted, secure, and efficient document signing.',
    items: [
      {
        id: '1',
        image: productsBg1,
        header: 'Platform',
        content:
          'The platform provides easy-to-use interface to document owner and document signer, allowing them to simply sign and manage the documents, as well as verifying the history of the signed documents.'
      },
      {
        id: '2',
        image: productsBg2,
        header: 'Browser Wallet',
        content:
          "The browser wallet manages users' private keys, decentralized identities, and verifiable credentials, which enable flexible authentication to verify users' identities and strong privacy protection to safeguard users' documents."
      },
      {
        id: '3',
        image: productsBg3,
        header: 'Mobile Application',
        content:
          "The mobile application offers similar functionalities to the platform for users to sign and manage their documents. Furthermore, it securely manages users' private key and associated credentials."
      }
    ]
  },
  introduce: {
    header: 'What can you do with TezSign?',
    content: '',
    items: [
      {
        id: '1',
        header: 'Verifiable Database',
        content:
          'An immutable and tamper-evident storage that supports accountability and data provenance verification.'
      },
      {
        id: '2',
        header: 'Decentralized Identity',
        content:
          "It stores user's identity in a decentralized manner, allowing individuals to fully manage their personal information."
      },
      {
        id: '3',
        header: 'Trusted Authentication',
        content:
          'Fine-grained authentication based on verifiable credentials, providing high-level trust to individuals in signing.'
      },
      {
        id: '4',
        header: 'Secure Document Sharing',
        content:
          'Securely share documents via public-key cryptography, ensuring only qualified signers can view your documents.'
      },
      {
        id: '5',
        header: 'Interoperable Blockchains',
        content:
          'Facilitate different blockchain systems to communicate with each other, such that users from other blockchains can easily be integrated.'
      },
      {
        id: '6',
        header: 'Artificial Intelligence',
        content:
          'Utilize AI technologies to detect fraudulent signatures or suspicious signing actions, improving the trustiness of document signing.'
      }
    ]
  },
  resources: {
    path: 'FAQ',
    header: 'Frequently Asked Questions',
    content:
      'We have answers (well, most of the times!). Find answers to commonly asked question about TezSign.',
    items: [
      {
        image: '',
        header: 'How do I know the signers are authenticated?',
        content:
          'Our platform provides a variety of authentication methods to verify the signers’ identities, including passcode, two-factor authentication, and decentralized identity. We ensure that only qualified signers can sign on the document.'
      },
      {
        image: '',
        header: 'How safe is my document?',
        content:
          'Documents will be kept in a decentralized cloud storage. There will be encryption on the uploaded file, breaking it down into 80 pieces, and then distributes them across thousands of nodes in multiple regions. The document requires a fraction of its distributed pieces to reassemble the file so that users do not need to worry about a single point of failure.'
      },
      {
        image: '',
        header: 'How do I verify the signed document?',
        content:
          'Go to "Verify Document" tab at the top of this web page.  Drag and drop your TezSign file sent to you to verify it is from the correct source.'
      },
      {
        image: '',
        header: 'Will my monthly/annual subscription be renewed automatically?',
        content:
          'Your document is highly protected via standard encryption techniques, only qualified signers can open and read the content of the document.'
      },
      {
        image: '',
        header: 'How do I cancel or downgrade my subscription?',
        content:
          'You can upload the original copy of the document to the frontend, our platform will retrieve the actions history of this document, which are recorded in the immutable storages, either verifiable database or blockchain.'
      }
    ]
  },
  plans: {
    path: 'pricing',
    header: 'Choose The Best Plan for Your Business',
    content: 'Free Trial for 3 Document Case. No credit card required.',
    items: [
      {
        // image: empty_profile,
        id: '1',
        header: 'Basic Plan',
        describe: 'For basic, lightweight users',
        price: [
          {
            id: '1',
            price: 'Free Trial',
            describe: 'Single user only'
          }
        ],
        content: [
          'Limited to 5 document/month',
          'Electronic signing options',
          'Multi-document signing options',
          'Email/SMS authentication options',
          'Approval/checker workflows',
          'Real-time audit trail',
          'Activity tracking and notifications',
          'Custom workflows'
        ]
      },
      {
        // image: empty_profile,
        header: 'Standard Plan',
        describe: 'For small to mid-size companies',
        price: [
          {
            id: '2',
            classify: 'Annual',
            price: '$228',
            describe: '/ year per user (incl 8% GST)'
          },
          {
            id: '3',
            classify: 'Monthly',
            price: '$25',
            describe: '/ month per user (incl 8% GST)'
          }
        ],
        content: [
          '100 documents/month',
          'Electronic and digital signing options',
          'Multi-document signing options',
          'Email/SMS authentication options',
          'Approval/checker workflows',
          'Real-time audit trail',
          'Activity tracking and notifications',
          'Custom workflows'
        ]
      },
      {
        // image: empty_profile,
        header: 'Premium Plan',
        describe:
          'For teams with additional requirements including APIs, custom workflows or volume transactions',
        price: [
          {
            id: '1',
            classify: 'Annual',
            price: '$400',
            describe: '/ year per user (incl 8% GST)'
          },
          {
            id: '2',
            classify: 'Monthly',
            price: '$38',
            describe: '/ month per user (incl 8% GST)'
          }
        ],
        content: [
          'Unlimited',
          'Electronic and digital signing options (DID and Singpass)',
          'Multi-document signing options',
          'Multi-documents sending',
          'Email/SMS authentication options',
          'Approval/checker workflows',
          'Real-time audit trail',
          'Activity tracking and notifications',
          'Custom workflows',
          'Template management and user management up to 20 pax',
          'Custom branding',
          'Smart forms'
        ]
      }
    ]
  },
  solutions: {
    header: 'Solutions',
    path: 'solutions',
    content:
      'See how you can transform customer and employee experiences in your industry.',
    items: [
      {
        id: '1',
        logo: '',
        bgImage: '',
        header: 'Financial Services',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      },
      {
        id: '2',
        logo: '',
        bgImage: '',
        header: 'Insurance',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      },
      {
        id: '3',
        logo: '',
        bgImage: '',
        header: 'Life Sciences',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      },
      {
        id: '4',
        logo: '',
        bgImage: '',
        header: 'Education',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      },
      {
        id: '5',
        logo: '',
        bgImage: '',
        header: 'Government',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      },
      {
        id: '6',
        logo: '',
        bgImage: '',
        header: 'Healthcare',
        describe:
          'Capture and share sensitive information securely with seamless, paperless experiences today’s customers expect.',
        content: [
          {
            id: 'sub1',
            title: 'Easy',
            describe:
              'Let your customers and employees complete online applications and sign electronically from any device, wherever they are.'
          },
          {
            id: 'sub2',
            title: 'Efficient',
            describe:
              'Process new account requests, loan applications, and preapprovals in minutes, not days.'
          },
          {
            id: 'sub3',
            title: 'Connected',
            describe:
              'Integrations and APIs built for Microsoft applications and others allow you to work seamlessly with the systems you’re already using every day.'
          },
          {
            id: 'sub4',
            title: 'Compliant',
            describe:
              'We adhere to rigorous global security, compliance, and legal requirements for our open, standards-based e-signature and data management solutions.'
          }
        ]
      }
    ]
  },
  contact: {
    path: 'contact',
    header: 'Ready for end-to-end digital processes?',
    content:
      'No matter where you are in your journey, take the next step with TezSign.',
    options: [
      'Product Features',
      'Request A Demo',
      'Investment Opportunities',
      'Technical Support'
    ],
    action: 'Submit'
  },
  partners: {
    header: 'Partners',
    items: [
      { image: partnersSbip, name: 'SBIP', link: 'https://sbip.sg/' },
      { image: partnersNus, name: 'NUS', link: 'https://nus.edu.sg/' },
      // {
      //   image: partners_soc,
      //   name: 'NUS School of Computing',
      //   link: 'https://www.comp.nus.edu.sg/'
      // },
      {
        image: partnersNusdbsys,
        name: 'NUS DB System',
        link: 'https://www.comp.nus.edu.sg/~dbsystem/'
      }
    ]
  },
  footer: {
    socialMedia: [
      {
        icon: <i className="bi bi-linkedin" />,
        label: '',
        link: 'https://www.linkedin.com/company/tezsign/?viewAsMember=true'
      },
      {
        icon: <i className="bi bi-instagram" />,
        label: '',
        link: 'https://instagram.com/sbip.sg?igshid=YmMyMTA2M2Y='
      },
      {
        icon: <i className="bi bi-facebook" />,
        label: '',
        link: 'https://www.facebook.com/TezSign-106196855443194'
      }
    ],
    subheader: 'Singapore Blockchain Innovation Programme',
    address:
      'Address • 21 Heng Mui Keng Terrace, #03-47 Icube Building, Singapore 119613.',
    email: 'Email • contact@tezsign.com',
    copyright: `Copyright ${new Date().getFullYear()} © TezSign. All rights reserved.`,
    others: [
      {
        name: 'Terms of use',
        id: 'termsofuse',
        path: '/terms-of-use',
        content: `These Terms & Conditions ("Agreement") govern your use of the e-signature service ("Service") provided by TezSign ("Provider").
        By accessing or using the Service, you agree to be bound by these terms and conditions. If you do not agree with any part of this Agreement, you should not use the Service.`,
        items: [
          {
            header: '1. Service Description',
            content: `<b>1.1</b> The Service allows users to electronically sign documents, contracts, and other digital content.
            <div style="padding:0.5rem"></div>
            <b>1.2</b> The Service may also include features such as document storage, form filling, and collaboration tools.
            <div style="padding:0.5rem"></div>
            <b>1.3</b> The Provider reserves the right to modify, suspend, or discontinue the Service at any time without prior notice.`
          },
          {
            header: '2. User Responsibilities',
            content: `<b>2.1</b> You are responsible for maintaining the confidentiality of your account information and password.
            <div style="padding:0.5rem"></div>
            <b>2.2</b> You must provide accurate and complete information when using the Service.
            <div style="padding:0.5rem"></div>
            <b>2.3</b> You must not use the Service for any illegal or unauthorized purposes.`
          },
          {
            header: '3. Electronic Signatures',
            content: `<b>3.1</b> The Service enables the creation of electronic signatures for documents.
            <div style="padding:0.5rem"></div>
            <b>3.2</b> By using the Service, you acknowledge and agree that electronic signatures have the same legal effect as handwritten signatures.
            <div style="padding:0.5rem"></div>
            <b>3.3</b> You are responsible for verifying the identity of the signatory and ensuring their consent to use electronic signatures.`
          },
          {
            header: '4. Beta Testing',
            content: `<b>4.1</b> From time to time, TezSign may offer beta testing of new features, products, or services on the Website. 
            Participation in beta testing is voluntary, and you agree to use any beta features, products, or services at your own risk.
            <div style="padding:0.5rem"></div>
            <b>4.2</b> TezSign makes no guarantees or warranties regarding the functionality or stability of beta features, products, or services and reserves the right to modify or discontinue them at any time.`
          },
          {
            header: '5. Document Integrity and Security',
            content: `<b>5.1</b> The Provider employs security measures to protect the integrity and confidentiality of your documents.
            <div style="padding:0.5rem"></div>
            <b>5.2</b> However, the Provider cannot guarantee the absolute security of electronic communications and storage.
            <div style="padding:0.5rem"></div>
            <b>5.3</b> You are responsible for maintaining backups of your documents and data.`
          },
          {
            header: '6. Intellectual Property',
            content: `<b>6.1</b> The Service and its associated software, trademarks, logos, and content are the intellectual property of the Provider.
            <div style="padding:0.5rem"></div>
            <b>6.2</b> You may not copy, modify, distribute, or reproduce any part of the Service without prior written permission from the Provider.`
          },
          {
            header: '7. Privacy',
            content: `<b>7.1</b> The Provider collects and processes personal information in accordance with its Privacy Policy.
            <div style="padding:0.5rem"></div>
            <b>7.2</b> By using the Service, you consent to the Provider's collection, use, and disclosure of personal information as described in the Privacy Policy.`
          },
          {
            header: '8. Limitation of Liability',
            content: `<b>8.1</b> The Provider shall not be liable for any direct, indirect, incidental, or consequential damages arising out of or in connection with the use of the Service.
            <div style="padding:0.5rem"></div>
            <b>8.2</b> The Service is provided on an "as is" basis, without any warranties or representations, express or implied.`
          },
          {
            header: '9. Termination',
            content: `<b>9.1</b> The Provider may terminate or suspend your access to the Service at any time without prior notice.
            <div style="padding:0.5rem"></div>
            <b>9.2</b> Upon termination, your right to use the Service will cease immediately.`
          },
          {
            header: '10. Governing Law',
            content: `<b>10.1</b> This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].
            <div style="padding:0.5rem"></div>
            <b>10.2</b> Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].`
          },
          {
            header: '11. Entire Agreement',
            content: `<b>11.1</b> This Agreement constitutes the entire agreement between you and the Provider regarding the use of the Service and supersedes any prior agreements or understandings.`
          }
        ]
      },
      {
        name: 'Privacy Policy',
        id: 'privacypolicy',
        path: '/privacy-policy',
        content: `This Privacy Policy outlines the manner in which we collect, use, disclose, and safeguard personal information obtained through our e-signature service (referred to as "Service").
        We are committed to protecting the privacy of individuals who utilize our Service and strive to ensure that any personal information provided is handled securely and in accordance with applicable data protection laws and regulations.
        By accessing or using our Service, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.`,
        items: [
          {
            header: '1. Information We Collect',
            content: `<b>Personal Information</b><div style="padding:0.1rem"></div>
            We may collect certain personal information, which may include but is not limited to:
            <ul style="list-style-type:disc; margin-top:0.5rem;margin-bottom:0.5rem">
              <li>Contact information (e.g., name, email address, phone number).</li>
              <li>User identification and authentication data.</li>
              <li>Digital signatures and associated documents.</li>
              <li>IP addresses, device information, and other technical information.</li>
            </ul>
            <b>Non-Personal Information</b><div style="padding:0.1rem"></div>
            We may collect non-personal information, such as aggregated and anonymized data, to improve our Service and enhance user experience. This information does not directly identify individuals.`
          },
          {
            header: '2. Use of Information',
            content: `We use the collected information for the following purposes:
            <ul style="list-style-type:disc; margin-top:0.5rem;margin-bottom:0.5rem">
              <li>Providing and improving our Service, including technical support.</li>
              <li>Verifying user identity and facilitating e-signature processes.</li>
              <li>Communicating with users, responding to inquiries, and delivering important notices.</li>
              <li>Analyzing usage patterns and trends to enhance our Service.</li>
              <li>Complying with applicable legal obligations and protecting our rights and interests.</li>
            </ul>`
          },
          {
            header: '3. Data Sharing and Disclosure',
            content: `<b>Service Providers</b><div style="padding:0.1rem"></div>
            We may engage trusted third-party service providers who assist us in delivering our Service.
            These providers are authorized to access personal information only as necessary to perform their functions 
            and are obligated to maintain the confidentiality and security of the data.<div style="padding:0.5rem"></div>
            <b>Legal Requirements</b><div style="padding:0.1rem"></div>
            We may disclose personal information if required by law, regulation, legal process, or governmental request, 
            or if we believe in good faith that disclosure is necessary to protect our rights, enforce our policies, or 
            investigate potential violations.<div style="padding:0.5rem"></div>
            <b>Business Transfers</b><div style="padding:0.1rem"></div>
            In the event of a merger, acquisition, or sale of all or a portion of our assets, personal information 
            may be transferred as part of the transaction. We will notify users via prominent notice on our website 
            and obtain their consent if required by applicable laws.`
          },
          {
            header: '4. Data Security',
            content: `We employ industry-standard security measures to protect the personal information provided through our Service. 
            However, please note that no method of transmission or storage is 100% secure, and we cannot guarantee the absolute security of your information. 
            Users are responsible for maintaining the confidentiality of their login credentials and should not share them with unauthorized individuals.`
          },
          {
            header: '5. Third-Party Links',
            content: `Our Service may contain links to third-party websites or services. 
            We are not responsible for the privacy practices or content of such websites. 
            We encourage users to review the privacy policies of these third parties before providing any personal information.`
          },
          {
            header: `6. Children's Privacy`,
            content: `Our Service is not intended for individuals under the age of 13. We do not knowingly collect personal information from children. If you believe that we have inadvertently collected personal information from a child, please contact us immediately, and we will take appropriate steps to remove the information from our records.`
          },
          {
            header: '7. Changes to this Privacy Policy',
            content: `We reserve the right to modify or update this Privacy Policy at any time. 
            Any changes will be effective upon posting the revised policy on our website. 
            We encourage users to review this Privacy Policy periodically for any updates. 
            Your continued use of our Service after the changes constitutes acceptance of the revised Privacy Policy.`
          },
          {
            header: '8. Contact Us',
            content: `If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <a href = "mailto: contact@tezsign.com">contact@tezsign.com.</a>
            <div style="padding:0.5rem"></div>
            Please note that this Privacy Policy applies solely to the information collected through.`
          }
        ]
      }
      // {
      //   name: 'Cookie Preferences',
      //   path: ''
      // }
    ]
  },
  requestDemo: {
    header: 'Solutions for teams of all sizes',
    content:
      "Every team and business has different needs. That's why we offer a full range of enterprise solutions, including flexible pricing for agencies and subscription options for teams of any size and budget."
  }
};

export const navBarRoute = [
  { name: 'Home', path: '#home', id: 'home' },
  { name: 'About', path: '#about', id: 'about' },
  { name: 'Products', path: '#products', id: 'products' },
  { name: 'Solutions', path: '#solutions', id: 'solutions' },
  { name: 'Pricing', path: '#pricing', id: 'pricing' },
  { name: 'FAQ', path: '#FAQ', id: 'FAQ' },
  { name: 'Verify Document', path: '/verify', id: 'verifydocument' },
  { name: 'Contact', path: '#contact', id: 'contact' }
];

export const VerifyDocument = {
  header: 'Verify Your TezSign Document',
  subheader:
    'Powerful integrations for modern workplaces, verify your TezSign document.',
  items: [
    {
      id: '1',
      title: 'Verify your Project Content',
      content:
        'Real-time verification provides up-to-date information of your project content'
    },
    {
      id: '2',
      title: 'The Key to Verification Lies in Your Document',
      content: 'Only document registered with TezSign can be verified'
    },
    {
      id: '3',
      title: 'Signing Certificate',
      content: 'View your signing certificate'
    }
  ]
};
