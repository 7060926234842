import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ConfigProvider } from 'antd';
import { generateKeycloak } from './services/keyclockService';
import Layout from './layouts/Layout';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-phone-number-input/style.css';
import './helpers/initFA';
import 'antd/dist/reset.css';

const keycloakProviderInitConfig = {
  onLoad: 'check-sso'
};

const defaultKeycloak = generateKeycloak();

const App = () => {
  TimeAgo.addLocale(en);
  const [keycloakins, setKeycloakins] = useState(defaultKeycloak);
  useEffect(() => {
    window.KEYCLOAKINSTANCE = keycloakins;
  }, [keycloakins]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F59700'
        }
      }}
    >
      <ReactKeycloakProvider
        authClient={keycloakins}
        initConfig={keycloakProviderInitConfig}
        // onEvent={onKeycloakEvent}
        // onTokens={onKeycloakTokens}
      >
        <Router>
          <Layout setKeycloak={setKeycloakins} />
          <ToastContainer
            pauseOnHover={false}
            closeButton
            hideProgressBar
            closeOnClick={false}
            position={toast.POSITION.TOP_RIGHT}
          />
        </Router>
      </ReactKeycloakProvider>
    </ConfigProvider>
  );
};

export default App;
