import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isContentEditable } from '@testing-library/user-event/dist/utils';
import styles from './index.module.css';
import { deleteFeatures } from '../../../services/feature';
import MyDropdownBtn from '../DropdownBtn';
import { FeatureTypesOptions } from '../../../helpers/maps';

const FeatureTable = ({ tableList, getListData }) => {
  const navigate = useNavigate();
  const handleEdit = (data, label) => {
    navigate(`/create-feature/${data.id}`);
  };

  const handleDelete = async (data, label) => {
    await deleteFeatures(data.id);
    toast.success('Successfully deleted');
    await getListData();
  };
  return (
    <div className="container-fluid mt-4 px-0">
      <Row className="table_header mx-0 bodybold">
        <Col md="4">Feature Name</Col>
        <Col md="4">Description</Col>
        <Col md="1">Type</Col>
        <Col md="2" className="text-center">
          Use Count
        </Col>
        <Col md="1" />
      </Row>
      {tableList.length ? (
        tableList.map(item => {
          return (
            <Row className={`${styles.table_row} mx-0`} key={item.id}>
              <Col md="4">{item.featureName}</Col>
              <Col md="4">{item.description}</Col>
              <Col md="1">
                {item.featureType
                  ? FeatureTypesOptions.filter(
                      i => i.value === item.featureType
                    )[0]?.label
                  : null}
              </Col>
              <Col md="2" className="text-center">
                {item.useCount}
              </Col>
              <Col md="1" className="text-center">
                {item.useCount === 0 && (
                  <MyDropdownBtn
                    handleEdit={handleEdit}
                    data={item}
                    handleDelete={handleDelete}
                    label="feature"
                  />
                )}
              </Col>
            </Row>
          );
        })
      ) : (
        <Row>
          <Col className="text-center pt-20 default-color-light bodylarge">
            You don't have any data
          </Col>
        </Row>
      )}
    </div>
  );
};

FeatureTable.propTypes = {
  tableList: PropTypes.arrayOf(PropTypes.shape({})),
  getListData: PropTypes.func.isRequired
};
FeatureTable.defaultProps = {
  tableList: null
};
export default FeatureTable;
