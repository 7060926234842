import React, { useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import './styles.css';

const MyTooltip = ({ content, position = 'right' }) => {
  const target = useRef(null);

  const renderTooltip = props => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      id="button-tooltip"
    >
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: content
        }}
      />
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement={position}
      target={target.current}
      // trigger={'click'}
      // delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <i
        ref={target}
        style={{ cursor: 'pointer' }}
        className="bi bi-question-circle-fill ms-2 light-color"
      />
    </OverlayTrigger>
  );
};

MyTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  position: PropTypes.string
};
MyTooltip.defaultProps = {
  position: 'right'
};

export default MyTooltip;
